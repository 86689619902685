
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        









.profile__hero-outer {
  @include mq($until: m) {
    background-color: $c-white;
  }

  @include mq(m) {
    height: 100vh;
    background: linear-gradient(
      -90deg,
      rgba($c-white, 1) 0%,
      rgba($c-white, 1) 60%,
      rgba($c-gray-lightest, 0) 60%,
      rgba($c-gray-lightest, 0) 100%
    );
  }
}

.profile__hero {
  display: flex;
  align-items: center;

  &::v-deep {
    .contact-infos__picture {
      height: 50rem;
    }
  }

  @include mq(m) {
    height: 100vh;

    &::v-deep {
      .contact-infos__picture {
        height: 100%;
      }
    }
  }
}

.profiles__list {
  padding-top: $spacing * 2;

  @include mq(m) {
    padding-top: $spacing * 3;
  }

  @include mq(xl) {
    padding-top: $spacing * 6;
  }
}
