
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        














































.blog-articles {
  background-color: $c-white;
}

.blog-articles-inner {
  padding: $spacing * 2 0;

  @include mq(m) {
    margin: 0 auto;
    padding: $spacing * 3.5 0;
  }

  @include mq(l) {
    padding: $spacing * 5 0;
  }
}

.blog-articles__headline {
  @extend %text-center;
}

.blog-articles__title {
  @extend %text-center;

  .blog-articles__headline + & {
    margin-top: $spacing;
  }
}

.blog-articles__text {
  margin-top: $spacing * 1.5;
}

.blog-articles__btn-outer {
  display: flex;
  justify-content: center;
}

.blog-articles__btn {
  display: flex;
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 4;
  }
}

.blog-list__item {
  &::v-deep {
    .card__picture-outer {
      width: 6.7rem;
      height: 6.7rem;
      margin-top: 4rem;
      margin-left: 4rem;
    }

    .card__picture {
      @include image-fit(contain);
    }
  }
}
