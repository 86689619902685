
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        










































.blog-list {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  background-color: $c-white;
}

.blog-list__items-outer {
  @include mq(m) {
    margin-right: $spacing * 3;
    margin-left: $spacing * 3;
  }

  @include mq(xl) {
    margin-right: $spacing * 6;
    margin-left: $spacing * 6;
  }

  @include mq(xxl) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.blog-list__title {
  .is-profiles & {
    display: none;
  }
}

.blog-list__filter-outer {
  position: relative;
  z-index: 9;
  max-width: 80rem;
  padding: 0 0 $spacing * 2;

  @include mq(m) {
    margin: 0 auto;
  }
}

.blog-list__items {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
  margin: $spacing * 2 -$spacing * 1.5 0;
  padding: $spacing * 1.5 0;

  .blog-list__item {
    width: 100%;
    // margin: $spacing * 2;
    max-width: none;
  }

  // @include mq($until: m) {
  //   justify-content: space-between;
  // }

  @include mq(s) {
    margin: $spacing * 2 -1% 0;
    padding: $spacing * 2 0;

    .blog-list__item {
      width: calc(100% / 2 - 2rem);
    }
  }

  @include mq(l) {
    margin: $spacing * 2 -1.2vw 0;
    padding: 0;

    .blog-list__item {
      width: calc(100% / 3 - 2rem);
    }
  }
}

.blog-content__loadmore-outer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: $spacing * 4;
}
